.sidebar {
  /*flex: 1;*/
  height: calc(100vh - 50px);
  background-color: #FFFFFF;
  position: sticky;
  top: 50px;
  width: 250px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin:6px 0 6px 0;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  /*background-color: #2096D9;*/
  color: #2096D9;

}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
  color: inherit;
}
.navy {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .sidebar {
    display: none;
  }
  .navy {
    display: block;
  }
  .sidebarListItem {
    color: azure;
  }

}
