.container1{
    display: flex;
    /*margin-top: 10px;*/
}
.container2{
    box-shadow: 10px 10px 0px 42px rgba(14,197,237,0.49);
    -webkit-box-shadow: 10px 10px 0px 42px rgba(14,197,237,0.49);
    -moz-box-shadow: 10px 10px 0px 42px rgba(14,197,237,0.49);
}


.link{
    text-decoration: none;
    color: inherit;
}
.active{
    color: #2096D9;
    text-decoration: none;
}
.formContainer{
    width: 40%;
}

.appMargin{
/*background: #F9F9F9;*/
}

.app-mobile-hide {
display: block;
}

.app-mobile-show {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .formContainer{
        width: 100%;
    }
    .appMargin{
        margin-top: 50px;
    }

    .app-mobile-hide {
        display: none;
    }

    .app-mobile-show {
        display: block;
    }
}

.otpContainer {
    margin: 5% auto;
}

.otpInput {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: #129CE9;
}


.gridCard:hover {
    /*background-color: #2096D9;*/
    border: 1px solid #2096D9;

}

.selectInput:hover {
    /*background-color: #2096D9;*/
    background: #2096D9;
    color: white;

}

.appBg-button{
    background: linear-gradient(105.78deg, #2FB5D9 30.68%, #6FDB7A 130.02%);
    border: 1px solid;
}

.error-template {padding: 40px 15px;text-align: center;}
.error-actions {margin-top:15px;margin-bottom:15px;}
.error-actions .btn { margin-right:10px; }


.cus-w {
    width: 50%;
}

@media only screen and (max-width: 1000px) {

    .cus-w {
        width: 100%;
    }

}

